const Monitor = (self) => ({

    getScripts() {
        return self.axios.get(self.getRestUrl(["monitor", "scripts"]))
    },
    startStopScript(payload) {
        return self.axios.post(self.getRestUrl(["monitor", "start_stop_ps"]), payload)
    },
    restart() {
        return self.axios.post(self.getRestUrl(["monitor", "restart"]))
    }

})

export default Monitor