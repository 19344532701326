import { StrategyInstanceHome } from "./StrategyInstanceHome";
import { StrategyInstanceEdit } from "./StrategyInstanceEdit";
import { Position } from "./Position";
import { useUrlParams } from "utils/useUrlParams"
import Box from "@mui/material/Box";

export function StrategyInstance() {
    document.title = "Strategy Instance";
    const [searchParams] = useUrlParams()

    return (
        <Box>
            {
                searchParams.get("type") === "edit" ? 
                    <StrategyInstanceEdit />
                :
                searchParams.get("type") === "position" ? 
                    <Position />
                : 
                    <StrategyInstanceHome />
            }
        </Box>
    )
}