import { CreateInstanceDialog } from "./CreateInstanceDialog";
import { SectionHeading } from "helpers/SectionHeading";
import InstanceDeleteModal from "./InstanceDeleteModal";
import DeleteIcon from '@mui/icons-material/Delete';
import { useUrlParams } from "utils/useUrlParams";
import RadarIcon from '@mui/icons-material/Radar';
import { useState, useEffect } from "react";
// import Edit from "@mui/icons-material/Edit";
import { mToast } from "Root/Common/Toast";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "helpers/Table";
import Box from "@mui/material/Box";
import { root } from "Root/root";

const columns = [
    { id: "tag", label: "Tag", minWidth: 10, align: "left"},
    { id: "users", label: "Users", minWidth: 100, align: "center" },
    { id: "index", label: "Index", minWidth: 100, align: "center" },
    // { id: "entry_time", label: "Entry Time", minWidth: 100, align: "center" },
    // { id: "exit_time", label: "Exit Time", minWidth: 100, align: "center" },
    { id: "lots", label: "Lots", minWidth: 100, align: "center" },
    { id: "call_type", label: "Call Type", minWidth: 100, align: "center" },
    { id: 'inst_action', label: "Action", minWidth: 10, align: 'right' }
]

export function StrategyInstanceHome() {
    const [, updateParams] = useUrlParams();
    const [isInstanceModalOpen, setIsInstanceModalOpen] = useState(false)
    
    const [isInstDelModalOpen, setIsInstDelModalOpen] = useState(false)
    const [selInstDelId, setSelInstDelId] = useState(null)
    const [selInstDelTag, setSelInstDelTag] = useState(null)

    const commonSel = useSelector(selector => selector.common)
    const [instances, setInstances] = useState({
        list: []
    })

    useEffect(() => {
        const createData = (inst = {}) => {
            return {
                tag: inst.tag,
                users: inst.users,
                index: inst.index,
                // entry_time: inst.entry_time,
                // exit_time: inst.exit_time,
                lots: inst.lots,
                call_type: inst.call_type,
                inst_action: (
                    <Box display="flex" justifyContent="end">
                        {/* <Link to={"/strategyInstance?type=edit&cfgId=" + inst.cfg_id + "&tag=" + inst.tag}>
                            <Button
                                startIcon={<Edit style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                            >
                                Edit
                            </Button>
                        </Link> */}

                        <Link to={"/strategyInstance?type=position&cfgId=" + inst.cfg_id + "&tag=" + inst.tag}>
                            <Button
                                startIcon={<RadarIcon style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                            >
                                Positions
                            </Button>
                        </Link>

                        <Link>
                            <Button
                                startIcon={<DeleteIcon style={{ transform: 'scale(.8)' }} />}
                                style={{ textTransform: "initial" }}
                                variant="text"
                                color="error"
                                onClick={() => {
                                    setIsInstDelModalOpen(true)
                                    setSelInstDelId(inst.cfg_id)
                                    setSelInstDelTag(inst.tag)
                                }}
                            >
                                Delete
                            </Button>
                        </Link>
                    </Box>
                )
            };
        }

        root.apis.strategyInstance.getInstances(commonSel.loginId)
        .then(data => {
            const list = (data.data.instances || []).map(createData)

            setInstances(prev => ({
                ...prev,
                list
            }))
        })
        .catch(err => {
            console.log("Get Instances Error: ", err)
            mToast.error(err.statusText)
        })
    }, [])

    return (
        <Box>
            <SectionHeading
                heading={"Strategy Instance"}
            >
                <Box>
                    <Button
                        variant="contained"
                        style={{ textTransform: 'initial' }}
                        onClick={() => {setIsInstanceModalOpen(true)}}
                    >
                        Create Instance
                    </Button>
                </Box>
            </SectionHeading>

            <Box mt={2}>
                <Table
                    columns={columns}
                    rows={instances.list}
                />
            </Box>

            <CreateInstanceDialog
                open={isInstanceModalOpen}
                onClose={() => setIsInstanceModalOpen(false)}
                setIsInstanceModalOpen={setIsInstanceModalOpen}
                commonSel={commonSel}
            />

            <InstanceDeleteModal 
                open={isInstDelModalOpen}
                handleClose={() => setIsInstDelModalOpen(false)}
                instCfgId={selInstDelId}
                instTag={selInstDelTag}
            />
        </Box>
    )

}