import { StrategyManagement } from "components/body/strategyManagement";
import { OrderConfiguration } from 'components/body/orderConfiguration';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { StrategyInstance } from "components/body/strategyInstance";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { UserAndAccounts } from 'components/body/userAndAccounts';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { LoginAccounts } from "components/body/loginAccounts";
import { MarketWatch } from 'components/body/marketWatch';
import { UserActions } from "components/body/userActions";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Positions } from 'components/body/positions';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArticleIcon from '@mui/icons-material/Article';
import FitbitIcon from '@mui/icons-material/Fitbit';
import MemoryIcon from '@mui/icons-material/Memory';
import { Archive } from 'components/body/archive';
import { Monitor } from "components/body/monitor";
import HomeIcon from '@mui/icons-material/Home';
import { Logs } from 'components/body/Logs';

export const asideMenus = [
    // {
    //     name: "Users",
    //     Icon: GroupIcon,
    //     routePathName: "users",
    //     component: Users,
    //     AsideComponent: AsideUsersList
    // },
    // {
    //     name: "Home",
    //     Icon: HomeIcon,
    //     routePathName: "home",
    //     component: Home,
    //     userType: "normal"
    // },
    {
        name: "Dashboard",
        Icon: HomeIcon,
        routePathName: "positions",
        component: Positions,
        userType: "normal",
        // onClick({navigate, user}) {
        //     if (!user.is_admin) {
        //         navigate(`/positions?type=view&userId=${user.id}&fullName=${user.full_name}&mtm=0&brokerUserId=${user.broker_user_id}`)
        //     }
        //     else {
        //         navigate("/positions")
        //     }
        // }
    },
    // {
    //     name: "Strategy Tracker",
    //     Icon: AutoGraphIcon,
    //     routePathName: "strategyTracker",
    //     component: StrategyTracker
    // },
    {
        name: "Manage Order",
        Icon: BorderColorIcon,
        routePathName: "orderConfiguration",
        component: OrderConfiguration,
        userType: "admin"
    },
    {
        name: "Market",
        Icon: ViewTimelineIcon,
        routePathName: "marketWatch",
        component: MarketWatch,
        userType: "normal"
    },
    {
        name: "Archive",
        Icon: ArchiveIcon,
        routePathName: "archive",
        component: Archive,
        userType: "normal"
    },
    {
        name: "Execution Logs",
        Icon: FitbitIcon,
        routePathName: "logs",
        component: Logs,
        userType: "admin"
    },
    {
        name: "Strategy",
        Icon: BorderColorIcon,
        routePathName: "strategyManagement",
        component: StrategyManagement,
        userType: "admin"
    },
    {
        name: "Strategy Instance",
        Icon: AccessTimeFilledIcon,
        routePathName: "strategyInstance",
        component: StrategyInstance,
        userType: "admin"
    },
    {
        name: "User Logs",
        Icon: ArticleIcon,
        routePathName: "userActions",
        component: UserActions,
        userType: "admin"
    },
    // {
    //     name: "Reports",
    //     Icon: AssessmentIcon,
    //     routePathName: "reports",
    //     component: Reports
    // }
    {
        name: "User APIs",
        Icon: ManageAccountsIcon,
        routePathName: "userAndAccounts",
        component: UserAndAccounts,
        userType: "admin"
    },
    {
        name: "Accounts",
        Icon: LockOpenIcon,
        routePathName: "loginAccounts",
        component: LoginAccounts,
        userType: "admin"
    },
    {
        name: "Monitor",
        Icon: MemoryIcon,
        routePathName: "monitor",
        component: Monitor,
        userType: "admin"
    }
];


export const asideMenusWidth = 245
export const topNavBarHeight = 90
